import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import logo from '@punchcard/core/assets/lyla-logo.png';
import { useMsal } from '@azure/msal-react';
import { Button } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { IconLogout } from '@punchcard/core/icons';

interface IProps {
	className?: string;
}

const NavBar = (props: IProps) => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const location = useLocation();

	return (
		<React.Fragment>
			<nav className={classNames('bg-light', 'navbar navbar-expand-lg navbar-dark shadow', props.className)}>
				<div className="container-fluid py-2 px-5">
					<div className="d-flex align-items-center">
						<Link to="/" className="navbar-brand">
							<img src={logo} alt="Punchcard" />
						</Link>
						<ul className="navbar-nav d-flex flex-row">
							<li className="nav-item">
								<Button
									to="/courses"
									className={classNames('btn-ghost-primary nav-link px-3 py-2', location.pathname != '/courses' && 'not-active')}
								>
									{t('navbar.courses')}
								</Button>
							</li>
							<li className="nav-item">
								<Button
									to="/schools"
									className={classNames('btn-ghost-primary nav-link px-3 py-2', location.pathname != '/schools' && 'not-active')}
								>
									{t('navbar.schools')}
								</Button>
							</li>
							<li className="nav-item">
								<Button
									to="/pick-lists"
									className={classNames('btn-ghost-primary nav-link px-3 py-2', location.pathname != '/pick-lists' && 'not-active')}
								>
									{t('navbar.pick_lists')}
								</Button>
							</li>
							<li className="nav-item">
								<Button
									to="/admin/users"
									className={classNames('btn-ghost-primary nav-link px-3 py-2', location.pathname != '/admin-users' && 'not-active')}
								>
									{t('navbar.admin_users')}
								</Button>
							</li>
						</ul>
					</div>
					<div className="bg-light">
						<ul className="navbar-nav ms-lg-auto">
							<li className="nav-item">
								<Button className="btn-outline-primary" onClick={() => instance.logoutRedirect()}>{t('logout')} <IconLogout className="ms-2" /></Button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</React.Fragment>
	);
};

// export default withRouter(NavBar);
export default NavBar;