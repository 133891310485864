function Typography() {

    return (
        <div>
            <h1>Typography</h1>
            <h2>Heading Styles</h2>
            <div>
                <div className='row'>
                    <h1 className='display-1'>Display 1</h1>
                </div>
                <div className='row'>
                    <h1 className='col-4'>Heading 1</h1>
                </div>
                <div className='row'>
                    <h2 className='col-4'>Heading 2</h2>
                </div>
                <div className='row'>
                    <h3 className='col-4'>Heading 3</h3>
                </div>
                <div className='row'>
                    <h4 className='col-4'>Heading 4</h4>
                </div>
                <div className='row'>
                    <h5 className='col-4'>Heading 5</h5>
                </div>
                <div className='row'>
                    <h6 className='col-4'>Heading 6</h6>
                </div>
            </div>
            <h2>Body Styles</h2>
            <div>
                <div className='row d-flex flex-row'>
                    <div className="col-6">
                        <p className=''>Regular</p>
                        <p className=''>The quick brown fox jumps over the lazy dog.</p>
                        <p className='fw-bold'>The quick brown fox jumps over the lazy dog.</p>
                        <p className='fst-italic'>The quick brown fox jumps over the lazy dog.</p>
                        <p className='fw-bold fst-italic'>The quick brown fox jumps over the lazy dog.</p>
                        <p className='text-decoration-underline'>The quick brown fox jumps over the lazy dog.</p>
                    </div>
                    <div className="col-6">
                        <p className=''>Small</p>
                        <p className=''><small>The quick brown fox jumps over the lazy dog.</small></p>
                        <p className='fw-bold'><small>The quick brown fox jumps over the lazy dog.</small></p>
                        <p className='fst-italic'><small>The quick brown fox jumps over the lazy dog.</small></p>
                        <p className='fw-bold fst-italic'><small>The quick brown fox jumps over the lazy dog.</small></p>
                        <p className='text-decoration-underline'><small>The quick brown fox jumps over the lazy dog.</small></p>
                    </div>
                </div>
            </div>
                  
        </div>
    )
}

export default Typography