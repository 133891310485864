import React from 'react';
import classNames from 'classnames';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	scrollable?: boolean;
}

const Page = (props: IProps) => {
	const { className, title, scrollable = true, ...otherProps } = props;

	React.useEffect(() => {
		if (title !== undefined && title !== '') {
			setDocTitle(props.title);
		}
	});

	return (
		<div className={classNames(scrollable && 'scroll-container', className)} {...otherProps} />
	);
};
function setDocTitle(value: string | undefined) {
	const titleSuffix = 'LYLA';
	if (value) {
		document.title = `${value} | ${titleSuffix}`;
	} else {
		document.title = titleSuffix;
	}
}

export default Page;
