import { Button, CheckboxGroup, FormGroup, Input, Radio, RadioGroup, TextArea } from "@punchcard/core"
import { PickListSelect } from "components/core/PicklistSelect"
import React from "react";
import { Controller, useForm } from "react-hook-form";

function Forms() {
    const {handleSubmit, control } = useForm();
    function onSubmit(data: any) {
        console.log(data);
    }
    const radiosOptions = [{ label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }];
    const checkboxOptions = [
        { label: 'Text goes here.', value: 'option1' },
        { label: 'Text goes here.', value: 'option2' },
        { label: 'Text goes here.', value: 'option3', disabled: true },
    ];
    return (
        <div>
            <h1>Forms</h1>
            <h2>States</h2>
            <div className="d-flex justify-content-between">
                <div className="w-100">
                    <div className='row'>
                        <div className="col-3">
                            <Controller
                                control={control}
                                name="input"
                                rules={{ required: 'Error message.' }}
                                render={({ field, fieldState }) =>
                                    <FormGroup label="Default" field={field} fieldState={fieldState}>
                                        <div className='d-flex'>
                                                <Input
                                                    {...field}
                                                    error={!!fieldState.error?.message}
                                                />
                                            </div>
                                    </FormGroup>
                                }
                            />
                        </div>
                        <div className="col-3">
                         <Controller
							control={control}
							name="input_disabled"
							rules={{ required: 'Enter a value' }}
                            disabled={true}
							render={({ field, fieldState }) =>
								<FormGroup label="Disabled" field={field} fieldState={fieldState}>
                                        <Input 
                                            {...field}
                                            error={!!fieldState.error?.message} 
                                        />
								</FormGroup>
							}
						/>
                        </div>
                        <div className="col-3">
                            <Button
                                className="btn-primary me-2"
                                onClick={handleSubmit(formData => handleSubmit(onSubmit))}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className="col-3">
                            <Controller
                                control={control}
                                name="text-area"
                                rules={{ required: 'Error message.' }}
                                render={({ field, fieldState }) =>
                                    <FormGroup label="Default" field={field} fieldState={fieldState}>
                                        <div className='d-flex'>
                                                <TextArea
                                                    {...field}
                                                    error={!!fieldState.error?.message}
                                                    ref={null}
                                                />
                                            </div>
                                    </FormGroup>
                                }
                            />
                        </div>
                        <div className="col-3">
                            <Controller
                                control={control}
                                name="text-area-disabled"
                                disabled={true}
                                rules={{ required: 'Error message.' }}
                                render={({ field, fieldState }) =>
                                    <FormGroup label="Disabled" field={field} fieldState={fieldState}>
                                        <div className='d-flex'>
                                                <TextArea
                                                    {...field}
                                                    error={!!fieldState.error?.message}
                                                    ref={null}
                                                />
                                            </div>
                                    </FormGroup>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <h2>Selects</h2>
            <div className="d-flex justify-content-between">
                <div className="w-100">
                    <div className='row'>
                        <div className='col-3'>
                            <Controller
                                control={control}
                                name="multiPicklist"
                                rules={{
                                    required: 'Error message.'
                                }}
                                render={({field, fieldState}) =>
                                    <FormGroup label="Default" field={field} fieldState={fieldState}>
                                        <PickListSelect
                                            picklistTypeCode="100"
                                            onChange={field.onChange}
                                            value={field.value}
                                            error={!!fieldState.error?.message}
                                        />
                                    </FormGroup>
					            }
				            />
                        </div>
                        <div className='col-3'>
                        <Controller
                                control={control}
                                name="multiPicklist-disabled"
                                disabled={true}
                                rules={{
                                    required: 'Error message.',
                                }}
                                render={({field, fieldState}) =>
                                    <FormGroup label="Disabled" field={field} fieldState={fieldState}>
                                        <PickListSelect
                                            isDisabled={true}
                                            picklistTypeCode="100"
                                            onChange={field.onChange}
                                            value={field.value}
                                            error={!!fieldState.error?.message}
                                        />
                                    </FormGroup>
					            }
				            />
                        </div>
                    </div>
                </div>
            </div>
            <h2>Controls</h2>
            <div className="d-flex justify-content-between">
                <div className="w-100">
                    <h3>Radios</h3>
                    <div className='row'>
                        <Controller
							control={control}
							name="radios"
							rules={{
								required: 'Error message.'
							}}
							render={({ field, fieldState }) =>
								<FormGroup label="Status : " field={field} fieldState={fieldState}>
									<RadioGroup
										value={field.value}
										onChange={field.onChange}
										options={radiosOptions}
										ref={null}
                                        horizontal={true}
									/>
								</FormGroup>
							}
						/>
                    </div>
                    <h3>Checkboxes</h3>
                    <div className='row'>
                        <Controller
							control={control}
							name="checkbox"
							rules={{
								required: 'Error message.'
							}}
							render={({ field, fieldState }) =>
								<FormGroup label="Status : " field={field} fieldState={fieldState}>
									<CheckboxGroup
										value={field.value}
										onChange={field.onChange}
										ref={null}
                                        horizontal={true}
                                        options={checkboxOptions}
									/>
								</FormGroup>
							}
						/>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default Forms