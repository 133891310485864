import React from 'react';
import classNames from 'classnames';
import { IconArrowLeft } from '@punchcard/core/icons';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';

interface IProps extends React.HTMLAttributes<HTMLElement> {
	title?: string;
	subTitle?: string;
	parentRoute?: string;
	titleClassName?: string;
	childrenClassName?: string;
	elementsBeforeTitle?: React.ReactNode;
}

const PageHero = (props: IProps) => {
	const { t } = useTranslation();
	const { title, elementsBeforeTitle, className, parentRoute, children, childrenClassName, titleClassName, ...otherProps } = props;
	return (
		<header
			className={
				classNames('container-fluid page-hero',
					title && children && 'd-flex align-items-center',
					title && children && !className?.includes('justify-content-') && 'justify-content-between',
					className)}
			{...otherProps}
		>
			<div className="page-hero-title">
				{parentRoute && <Button className="btn-ghost-primary ms-n3" icon={<IconArrowLeft />} to={`${parentRoute}`}> {t('navigation.back')}</Button>}
				{elementsBeforeTitle}
				{title && <h4 className={titleClassName}>{title}</h4>}
			</div>
			<div className={childrenClassName}>{children}</div>
		</header>
	);
};

export default PageHero;
