import React from 'react';
import classNames from 'classnames';
import ReactTable, { Filter } from 'react-table';
import 'react-table/react-table.css';
import { IconChevronLeft, IconChevronRight } from '@punchcard/core/icons';
import { Spinner, IconButton, ClearFilterButton } from '@punchcard/core';
import Pagination from '@punchcard/core/components/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface IProps {
	className?: string;
	filterable?: boolean;
	TableComponent?: any;
	columns?: any[];
	data?: any[];
	getTableProps?: (...args: any[]) => object;
	getTheadFilterThProps?: (...args: any[]) => object;
	getTheadGroupProps?: (...args: any[]) => object;
	getTheadProps?: (...args: any[]) => object;
	getTheadThProps?: (...args: any[]) => object;
	getTbodyProps?: (...args: any[]) => object;
	getTrProps?: (...args: any[]) => object;
	getTdProps?: (...args: any[]) => object;
	minRows?: number;
	hasActions?: boolean;
	resizable?: boolean;
	sortable?: boolean;
	filtered?: any[];
	onFilteredChange?: any;
	noDataText?: string;
	showPagination?: boolean;
	onPageChange?: (pageIndex: number) => void;
	// handles clicks on each data table row, if not provided nothing happens
	onRowClick?: ({ row, original }: { row?: any, original?: any }) => void;
	loading?: boolean;
	pageSize?: number;
	showClearButton?: boolean;
}

const DataTable = (props: IProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const onNavigate = (e: any, callback: () => void) => {
		// TODO: Find a better way to animate the page
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		callback();
	};

	const getCellWrapStyle = (styleType: string) => {
		const breakWordWrapStyle: React.CSSProperties = {
			whiteSpace: 'normal',
			wordWrap: 'break-word',
		};

		const ellipsisWrapStyle: React.CSSProperties = {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		};

		if (styleType === 'wrap') {
			return breakWordWrapStyle;
		} else if (styleType === 'ellipsis') {
			return ellipsisWrapStyle;
		} else {
			return undefined;
		}
	};

	const setColumnsWithClearButton = (): any[] | undefined => {
		if (props.columns) {
			const newColumn = props.columns.map((column: any) => {
				if (column.showClearButton) {
					return { ...column, Filter: <ClearFilterButton onClick={clearFilters} /> };
				} else {
					const wrapStyle = getCellWrapStyle(column.wrapStyle || 'ellipsis');
					if (column.Cell) {
						return {
							...column,
							Cell: (props: any) => (
								<div
									style={wrapStyle}
								>
									{typeof column.Cell === 'function'
										? column.Cell(props)
										: props.value}
								</div>
							)
						};
					} else {
						return {
							...column,
							Cell: (props: any) => (
								<div
									style={wrapStyle}
								>
									{props.value}
								</div>
							)
						};
					}
				}
			});
			return newColumn;
		}
		return props.columns;
	};
	const clearFilters = () => {
		const searchParams = new URLSearchParams(location.search);
		const keysToDelete: string[] = [];
		searchParams.forEach((value, key) => {
			if (key !== 'page' && key !== 'sort') {
				keysToDelete.push(key);
			}
		});
		// Delete keys
		keysToDelete.forEach((key) => {
			searchParams.delete(key);
		});
		navigate({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const searchParams = new URLSearchParams(location.search);
	const page = searchParams.get('page') === null ? 0 : Number(searchParams.get('page')) - 1;
	const assignDefaultSearchParams = () => {
		const filter: Filter[] = [];
		searchParams.forEach((value, key) => {
			if (key !== 'page' && key !== 'sort') {
				filter.push({ id: key, value });
			}
		});
		return filter;
	};


	const defaultFiltered: Filter[] | undefined = assignDefaultSearchParams();
	const getSort = () => {
		const sort = searchParams.get('sort');
		if (sort) {
			const sortArray = sort.split(' ');
			return [{ id: sortArray[0], desc: sortArray[1] === 'desc' }];
		}
	};
	const setSort = (sort: any) => {
		const searchParams = new URLSearchParams(location.search);
		const sortString = sort.length > 0 ? sort[0].id + ' ' + (sort[0].desc ? 'desc' : 'asc') : '';
		searchParams.set('sort', sortString);
		navigate({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const setPage = (page: number) => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.set('page', String(page));
		navigate({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};



	const setFilter = (filter: any) => {
		const searchParams = new URLSearchParams(location.search);
		const filterIds = filter.map((item: { id: string }) => item.id);
		const columnAccessors = columns!.map((column: { accessor: string }) => column.accessor);
		const columnAccessorsNotInFilter = columnAccessors.filter((accessor: string) => !filterIds.includes(accessor));	// remove any filtering from url that is not in the filter
		columnAccessorsNotInFilter.forEach((accessor: string) => searchParams.delete(accessor));
		filter.forEach((item: { id: string, value: string }) => {
			searchParams.set(item.id, item.value);
		});
		navigate({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	};

	const getTrProps = (_: any, rowInfo: any) => {
		const { onRowClick } = props;
		if (onRowClick) {
			return {
				className: 'tr cursor-pointer',
				onClick(e: any, handleOriginal: any) {
					onRowClick(rowInfo);
					if (handleOriginal) {
						handleOriginal();
					}
				}
			};
		} else {
			return {
				className: 'tr p-0'
			};
		}
	};

	const {
		className,
		filterable,
		hasActions,
		TableComponent,
		data,
		showPagination = true,
		columns,
		getTableProps = () => {
			return { className: 'table' };
		},
		getTheadFilterThProps = () => {
			return { className: 'th' };
		},
		getTheadGroupProps = () => {
			return { className: 'thead theadgroup' };
		},
		getTheadProps = () => {
			return { className: 'thead border-gray border-bottom bg-light' };
		},
		getTheadThProps = () => {
			return { className: 'td px-3 py-2 my-1 d-flex align-items-center' };
		},
		getTbodyProps = () => {
			return { className: 'tbody' };
		},
		getTdProps = () => {
			return { className: 'td px-3 py-2 my-1 d-flex align-items-center' };
		},
		minRows = 0,
		resizable = false,
		sortable = true,
		noDataText,
		filtered,
		loading,
		pageSize = 10,
	} = props;


	return (
		<ReactTable
			className={classNames('data-table', hasActions !== false && 'has-actions', className)}
			filterable={filterable}
			sorted={getSort()}
			onSortedChange={(newSort) => setSort(newSort)}
			onFilteredChange={(newFilter) => setFilter(newFilter)}
			filtered={filtered}
			defaultFilterMethod={(filter: { id: string | number; value: any; }, row: { [x: string]: any; }) => {
				return String(row[filter.id]).toLowerCase().includes(String(filter.value).toLowerCase());
			}}
			defaultFiltered={defaultFiltered}
			TableComponent={TableComponent}
			columns={setColumnsWithClearButton()}
			defaultPageSize={pageSize}
			data={data}
			showPagination={showPagination}
			PaginationComponent={({ page, onPageChange, onPageSizeChange, pageSize }) => (
				<Pagination
					totalItems={data?.length}
					itemsPerPage={pageSize}
					currentPage={page}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
				/>
			)}
			getTableProps={getTableProps}
			getTheadFilterThProps={getTheadFilterThProps}
			getTheadGroupProps={getTheadGroupProps}
			getTheadProps={getTheadProps}
			getTheadThProps={getTheadThProps}
			getTbodyProps={getTbodyProps}
			getTrProps={getTrProps}
			getTdProps={getTdProps}
			minRows={minRows}
			resizable={resizable}
			loading={loading}
			LoadingComponent={() => loading ? (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner className="spinner-large" />
				</div>
			) : null}
			sortable={sortable}
			PreviousComponent={(p: { onClick: () => void; disabled: boolean | undefined; }) => (
				<IconButton
					onClick={(e: any) => onNavigate(e, p.onClick)}
					disabled={p.disabled}
					className="btn-link"
				>
					<IconChevronLeft />
				</IconButton>
			)}
			NextComponent={(p: { onClick: () => void; disabled: boolean | undefined; }) => (
				<IconButton
					onClick={(e: any) => onNavigate(e, p.onClick)}
					disabled={p.disabled}
					className="btn-link"
				>
					<IconChevronRight />
				</IconButton>
			)}
			onPageChange={(page) => setPage(page + 1)}
			noDataText={!loading && noDataText}
			page={page}
		/>
	);
};

export default DataTable;