import React from 'react';
import { ActionIcon, Button, DataTable, filterByStatus, LoadingScreen, ModalOverlay, Page, PageHero, setStatusCellValue, SidebarModalForAddingData, StatusFilterMethod } from '@punchcard/core';
import { IconAdd, IconChevronRight, IconClose } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RowInfo } from 'react-table';
import adminAPI from 'api/AdminAPI';
import { toast } from 'react-toastify';

function SchoolSiteUsersList() {
	const { t } = useTranslation();
	const { tenantId, siteId } = useParams();
	const [loading, setLoading] = React.useState(false);
	const [schoolSiteUsers, setSchoolSiteUsers] = React.useState<AttributeUsersDTO>();
	const [showAssignSiteUsersModal, setShowAssignSiteUsersModal] = React.useState<boolean>(false);
	const [showRemoveUserModal, setShowRemoveUserModal] = React.useState<boolean>(false);
	const [selectedUser, setSelectedUser] = React.useState<AttributeXrefUserDTO | null>(null);

	async function getSiteUsers() {
		setLoading(true);
		const siteUsersResponse = await adminAPI.schoolSites.getSiteUsersBySiteId(
			parseInt(siteId ?? '0'),
			parseInt(tenantId ?? '0'));
		if (siteUsersResponse !== null) {
			setSchoolSiteUsers(siteUsersResponse);
		} else {
			toast.error(t('sites.unable_to_retrieve_site_users'));
		}
		setLoading(false);
	}

	React.useEffect(() => {
		getSiteUsers();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId, siteId]);

	const columns = [
		{
			Header: 'XrefUser ID',
			accessor: 'attributeXrefUserId',
			show: false
		},
		{
			Header: t('users.name'),
			accessor: 'name'
		},
		{
			Header: t('users.email_address'),
			accessor: 'email',
		},
		{
			Header: t('sites.sites'),
			accessor: 'allAttributeItemValues',
			Cell: (row : RowInfo) => {
				return row.original?.allAttributeItemValues?.join(', ');
			},
		},
		{
			Header: t('sites.userType'),
			accessor: 'role',
		},
		{
			Header: t('users.status'),
			accessor: 'status',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('status'),
			Filter: StatusFilterMethod
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title={t('remove')} icon={IconClose} onClick={() => { removeUser(rowInfo); }} />
				</React.Fragment>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},
	];

	function addUserToSite() {
		setShowAssignSiteUsersModal(true);
	}

	function removeUser(row: RowInfo) {
		setSelectedUser(row.original);
		setShowRemoveUserModal(true);
	}

	async function onConfirmRemoveUser() {
		setLoading(true);
		try {
			const result = await adminAPI.schoolSites.removeUserFromSite(
				parseInt(siteId ?? '0'),
				parseInt(tenantId ?? '0'),
				selectedUser?.attributeXrefUserId ?? 0);
			if (result) {
				toast.success(t('sites.successfully_removed_user'));
				getSiteUsers();
			} else {
				toast.error(t('sites.unable_to_remove_user'));
			}

		} catch (error) {
			toast.error(t('sites.unable_to_remove_user'));
		} finally {
			setLoading(false);
			setShowRemoveUserModal(false);
		}
	}

	const onAssignUsersToSite = async (selectedUserIds: number[]) => {
		const siteUsers = await adminAPI.schoolSites.addUsersToSite(
			parseInt(siteId ?? '0'),
			parseInt(tenantId ?? '0'),
			selectedUserIds);
		if (siteUsers) {
			toast.success(t('sites.successfully_added_users'));
			getSiteUsers();
		} else {
			toast.error(t('sites.unable_to_add_users'));
		}
	};

	const getUnassignedUsersList = async () => {
		const unassignedUsers = await adminAPI.schoolSites.getUnassignedUsersBySiteId(
			parseInt(siteId ?? '0'),
			parseInt(tenantId ?? '0'));
		if (unassignedUsers !== null) {
			return unassignedUsers.map((user) => {
				return {
					id: user.userId,
					value: user.userId,
					label: user.name
				};
			});
		} else {
			toast.error(t('unable_to_retrieve_school_list'));
		}
		return [];
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<Page title={t('sites.users')} className="bg-light p-3 ps-4">
			<PageHero
				title={t('sites.users')}
				parentRoute={`/schools/${tenantId}/sites`}
				elementsBeforeTitle={
					<div className="d-flex align-items-center justify-content-center">
						<h6>{schoolSiteUsers?.schoolName}</h6>
						<IconChevronRight className="icon-24" />
						<h6>{schoolSiteUsers?.attributeItemLabel}</h6>
					</div>
				}
			>
				<div className="d-flex justify-content-between">
					<div className="d-flex justify-content-end">
						<Button onClick={addUserToSite} className="btn-primary"><IconAdd />{t('sites.add_user')}</Button>
					</div>
				</div>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={schoolSiteUsers?.users}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('sites.no_user_on_site', { siteName: schoolSiteUsers?.attributeItemLabel })}
					loading={loading}
				/>
			</div>
			<SidebarModalForAddingData
				submitData={onAssignUsersToSite}
				getUnassignedDataList={getUnassignedUsersList}
				selectErrorMessage={t('sites.select_at_least_one_user')}
				showAssignDataModal={showAssignSiteUsersModal}
				setShowAssignDataModal={setShowAssignSiteUsersModal}
				headerLabel={t('sites.select_users')}
				confirmButtonLabel={t('add')}
				cancelButtonLabel={t('cancel')}
				formLabel={t('sites.users')}
			/>
			<ModalOverlay
				isOpen={showRemoveUserModal}
				modalSize="md"
				onRequestClose={() => setShowRemoveUserModal(false)}
				headerChildren={t('sites.remove_user')}
				confirmButtonChildren={t('remove')}
				cancelButtonChildren={t('cancel')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					onConfirmRemoveUser();
				}}
				cancelButtonAction={() => {
					setShowRemoveUserModal(false);
				}}
			>
				{t('sites.remove_user_message', { userName: selectedUser?.name, siteName: schoolSiteUsers?.attributeItemLabel })}
			</ModalOverlay>
		</Page>
	);
}

export default SchoolSiteUsersList;