/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IconArrowLeft, IconDelete, IconSave } from '@punchcard/core/icons';
import { Button, ModalOverlay } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate, BlockerFunction, useBlocker } from 'react-router-dom';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	parentRoute?: string;
	deleteEntity?: () => void;
	saveEntity?: () => void;
	isDirty?: boolean;
}

const EntityHeader = (props: IProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { title, parentRoute, deleteEntity, saveEntity, isDirty, children } = props;
	const [showWarningModal, setShowWarningModal] = React.useState<boolean>(false);

	React.useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty]);

	const handleBeforeUnload = (e: any) => {
		if (isDirty) {
			e.preventDefault();
		}
	};

	const shouldBlock = React.useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) => {
			if (isDirty) {
				if (nextLocation.pathname === parentRoute) {
					return false;
				}
			}
			return isDirty === true && currentLocation.pathname !== nextLocation.pathname;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDirty]
	);

	const blocker = useBlocker(shouldBlock);

	return (
		<header className="container-fluid p-0 page-hero" >
			<div className="page-hero-title">
				{parentRoute &&
				<Button
					className="btn-ghost-primary ms-n3"
					icon={<IconArrowLeft />}
					onClick={() => isDirty ? setShowWarningModal(true) : navigate(parentRoute)}
				>
					{t('navigation.back')}
				</Button>}
				<div className="d-flex justify-content-between align-items-center">
					{title && <h4 className="mb-0">{title}</h4>}
					<div className="d-flex">
						{deleteEntity &&
							<Button
								onClick={deleteEntity}
								className={classNames('btn-ghost-primary', saveEntity && 'me-3')}
								icon={<IconDelete style={{ width: 18, height: 18 }} />}
							>
								{t('navigation.delete')}
							</Button>
						}
						{saveEntity && <Button onClick={saveEntity} className="btn-primary" icon={<IconSave />}>{t('navigation.save_and_exit')}</Button>}
						{children}
					</div>
				</div>
			</div>
			<ModalOverlay
				isOpen={showWarningModal || blocker.state === 'blocked'}
				modalSize="md"
				onRequestClose={() => setShowWarningModal(false)}
				headerChildren={t('discard_changes')}
				confirmButtonChildren={t('confirm')}
				cancelButtonChildren={t('cancel')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					if (blocker.state === 'blocked') {
						blocker.proceed?.();
					}
					else if (parentRoute) {
						navigate(parentRoute);
					}
					setShowWarningModal(false);
				}}
				cancelButtonAction={() => {
					if (blocker.state === 'blocked') {
						blocker.reset?.();
					}
					setShowWarningModal(false);
				}}
			>
				{t('discard_changes_message')}
			</ModalOverlay>
		</header>
	);
};

export default EntityHeader;