import React from 'react';
import { Button, FormGroup, Input, LoadingPage, ModalOverlay, Page } from '@punchcard/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import EntityHeader from '@punchcard/core/components/EntityHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import adminAPI from 'api/AdminAPI';
import { toast } from 'react-toastify';

function SchoolSite() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { siteId, tenantId } = useParams();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [showDeleteSiteModal, setShowDeleteSiteModal] = React.useState<boolean>(false);
	const pageTitle = parseInt(siteId ?? '0') === 0 ? t('sites.add_site') : t('sites.edit_site');
	const methods = useForm<AttributeItemDTO>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			value: '',
			description: '',
		}
	});

	const { control, handleSubmit, reset, watch, formState } = methods;
	const { isDirty } = formState;

	const siteName = watch('value');

	React.useEffect(() => {
		async function fetchData() {
			setLoading(true);
			if (siteId !== '0') {
				const schoolSite = await adminAPI.schoolSites.getSchoolSiteById(
					parseInt(siteId ?? '0'),
					parseInt(tenantId ?? '0'),
				);
				reset(schoolSite);
			}
			setLoading(false);
		}
		fetchData();
	}, [reset, siteId, tenantId]);


	const submitData = async (data: AttributeItemDTO) => {
		setLoading(true);
		const schoolSite = await adminAPI.schoolSites
			.postSchoolSite(
				parseInt(siteId ?? '0'),
				parseInt(tenantId ?? '0'),
				data.value,
				data.description);

		if (schoolSite) {
			if (siteId === '0') {
				toast.success(t('sites.successfully_added_site'));
			} else {
				toast.success(t('sites.successfully_updated_site'));
			}
			navigate(-1);
		} else {
			if (siteId === '0') {
				toast.error(t('sites.unable_to_add_site'));
			} else {
				toast.error(t('sites.unable_to_update_site'));
			}
		}
		setLoading(false);
	};

	function onDeleteBtnClick() {
		setShowDeleteSiteModal(true);
	}

	async function deleteSite() {
		setLoading(true);
		const isDeleted = await adminAPI.schoolSites.deleteSchoolSite(
			parseInt(siteId ?? '0'),
			parseInt(tenantId ?? '0'),
		);

		if (isDeleted) {
			toast.success(t('sites.successfully_deleted_site'));
			navigate(`/schools/${tenantId}/sites`);
		} else {
			toast.error(t('sites.unable_to_delete_site'));
		}
		setLoading(false);
	}


	if (loading) {
		return <LoadingPage />;
	}

	return (
		<Page className="bg-light p-3 ps-5" title={pageTitle} >
			<FormProvider {...methods}>
				<div className="container-fluid">
					<div className="row">
						<div className=" col-lg-7">
							<EntityHeader
								title={pageTitle}
								parentRoute={`/schools/${tenantId}/sites`}
								deleteEntity={siteId !== '0' ? onDeleteBtnClick : undefined}
								isDirty={isDirty}
							/>
							<div className=" bg-white p-3 rounded-2 mt-3">
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="value"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('sites.name')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="description"
											render={({ field, fieldState }) =>
												<FormGroup label={t('sites.description')} fieldState={fieldState} className="pb-2">
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div>
									<Button onClick={handleSubmit(submitData)} className="btn-primary">{t('navigation.save')}</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormProvider>
			<ModalOverlay
				isOpen={showDeleteSiteModal}
				modalSize="md"
				onRequestClose={() => setShowDeleteSiteModal(false)}
				headerChildren={t('sites.delete_site')}
				confirmButtonChildren={t('sites.delete')}
				cancelButtonChildren={t('cancel')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					setShowDeleteSiteModal(false);
					deleteSite();
				}}
				cancelButtonAction={() => {
					setShowDeleteSiteModal(false);
				}}
			>
				{t('sites.want_delete_site', { siteName: siteName })}
			</ModalOverlay>
		</Page >
	);
}

export default SchoolSite;