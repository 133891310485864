import React from 'react';
import { Page, PageHero, DataTable, ActionIcon, LoadingScreen, Button, setStatusCellValue, filterByStatus, StatusFilterMethod } from '@punchcard/core';
import pickListAPI from 'api/pickListAPI';
import AuthorizationWrapper from 'auth/AuthorizationWrapper';
import { Permissions } from 'auth/Permissions';
import { useNavigate } from 'react-router-dom';
import { IconArrowRight, IconEdit } from '@punchcard/core/icons';

const PickListTypeListPage = () => {
	const [pickListTypes, setPickListTypes] = React.useState<PickListType[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const navigate = useNavigate();
	React.useEffect(() => {
		const fetchPickListTypes = async () => {
			setIsLoading(true);
			const res = await pickListAPI.getPickListTypes();
			setIsLoading(false);
			setPickListTypes(res);
		};

		fetchPickListTypes();
	}, []);

	const columns = [
		{
			Header: 'Code',
			accessor: 'code',
		},
		{
			Header: 'Description',
			accessor: 'description'
		},
		{
			Header: 'Status',
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			Header: 'Actions',
			accesor: 'id',
			showClearButton: true,
			Cell: (rowValue: any) => ( // turn into a component
				<React.Fragment>
					<ActionIcon title="Edit picklist type" icon={IconEdit} onClick={() => navigate(`/pick-lists/${rowValue.original.id}`)} />
					<ActionIcon title="Open picklist items" icon={IconArrowRight} onClick={() => navigate(`/pick-lists/${rowValue.original.id}/pick-list-items`)} />
				</React.Fragment>
			)
		}
	];

	if (isLoading) {
		return <LoadingScreen />;
	}

	return (
		<Page title="Pick list types" className="bg-light px-4">
			<PageHero title="Picklist Items">
				<AuthorizationWrapper type="hide" permissions={[Permissions.WritePickList]}>
					<Button className="btn-primary " to="/pick-lists/0">
						Add pickList type
					</Button>
				</AuthorizationWrapper>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={pickListTypes}
					columns={columns}
					filterable={true}
				/>
			</div>
		</Page>
	);
};

export default PickListTypeListPage;