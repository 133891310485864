import React from 'react';
import { ActionIcon, Button, DataTable, LoadingScreen, Page, PageHero } from '@punchcard/core';
import { IconAdd, IconEditUnfilled, IconUsers } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { RowInfo } from 'react-table';
import { useNavigate, useParams } from 'react-router-dom';
import adminAPI from 'api/AdminAPI';
import { toast } from 'react-toastify';

function SchoolSitesList() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { tenantId } = useParams();
	const [loading, setLoading] = React.useState(false);
	const [schoolSites, setSchoolSites] = React.useState<SchoolAttributeDTO>();

	async function getSchoolSites() {
		setLoading(true);
		const schoolSiteResponse = await adminAPI.schoolSites.getSchoolSiteList(parseInt(tenantId ?? '0'));
		if (schoolSiteResponse !== null) {
			setSchoolSites(schoolSiteResponse);
		} else {
			toast.error(t('sites.unable_to_retrieve_school_sites'));
		}
		setLoading(false);
	}

	React.useEffect(() => {
		getSchoolSites();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tenantId]);

	const columns = [
		{
			Header: 'Sites ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('sites.name'),
			accessor: 'label'
		},
		{
			Header: t('sites.description'),
			accessor: 'description',
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title={t('sites.users')} icon={IconUsers} onClick={() => { openSiteUsers(rowInfo); }} />
					<ActionIcon title={t('edit')} icon={IconEditUnfilled} onClick={() => { editSite(rowInfo); }} />
				</React.Fragment>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},
	];

	function addSiteToSchool() {
		navigate(`/schools/${tenantId}/sites/0`);
	}

	function openSiteUsers(row: RowInfo) {
		navigate(`/schools/${tenantId}/sites/${row.original.id}/users`);
	}

	function editSite(row: RowInfo) {
		navigate(`/schools/${tenantId}/sites/${row.original.id}`);
	}

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<Page title={t('sites.sites')} className="bg-light p-3 ps-4">
			<PageHero title={t('sites.sites')} parentRoute="/schools" elementsBeforeTitle={<h6>{schoolSites?.schoolName}</h6>}>
				<div className="d-flex justify-content-between">
					<div className="d-flex justify-content-end">
						<Button onClick={addSiteToSchool} className="btn-primary"><IconAdd />{t('sites.add_site')}</Button>
					</div>
				</div>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={schoolSites?.attributeItems}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('sites.no_site_on_school', { schoolName: schoolSites?.schoolName })}
					loading={loading}
				/>
			</div>
		</Page>
	);
}

export default SchoolSitesList;