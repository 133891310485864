import { Button, IconButton } from "@punchcard/core"
import { IconDelete } from "@punchcard/core/icons"

function Buttons() {
    return (
        <div>
            <h1>Buttons</h1>
            <h2>Sizes</h2>
            <div className="d-flex justify-content-between">
                <div className="w-100">
                    <div className='row'>
                        <div className='col-3'>
                            <h3>Large</h3>
                            <Button className='btn-primary-300'>Button</Button>
                            <IconButton className='btn-primary-300 ms-4'>
				                <IconDelete />
                            </IconButton>
                        </div>
                        <div className='col-3'>
                            <h3>Small</h3>
                            <Button className='btn-primary-300 btn-sm'>Button</Button>
                            <IconButton className='btn-primary-300 btn-sm ms-4'>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <h2>Filled</h2>      
            <div className="d-flex">
                <div className="w-100">
                    <div className='row d-flex flex-row'>
                        <div className='col-3'>
                            <h3>Default</h3>
                            <Button className='btn-primary-300'>Button</Button>
                            <IconButton className='btn-primary-300 ms-4'>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>

                        <div className='col-3'>
                            <h3>Disabled</h3>
                            <Button className='btn-primary' disabled={true}>Button</Button>
                            <IconButton className='btn-primary-300 ms-4' disabled={true}>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <h2>Outlined</h2>     
            <div className="d-flex">
                <div className="w-100">
                    <div className='row d-flex flex-row'>
                        <div className='col-3'>
                            <h3>Default</h3>
                            <Button className='btn-outline-primary'>Button</Button>
                            <IconButton className='btn-outline-primary-300 ms-4'>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>

                        <div className='col-3'>
                            <h3>Disabled</h3>
                            <Button disabled={true} className='btn-outline-primary-disabled'>Button</Button>
                            <IconButton className='btn-outline-primary-disabled ms-4' disabled={true}>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <h2>Ghost</h2>     
            <div className="d-flex">
                <div className="w-100">
                    <div className='row d-flex flex-row'>
                        <div className='col-3'>
                            <h3>Default</h3>
                            <Button className='btn-ghost-primary underline-text'>Button</Button>
                            <IconButton className='btn-ghost-primary-300 ms-4'>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>

                        <div className='col-3'>
                            <h3>Disabled</h3>
                            <Button disabled={true} className='btn-ghost-primary-disabled'>Button</Button>
                            <IconButton className='btn-ghost-primary-disabled ms-4' disabled={true}>
				                <IconDelete width="18" height="18"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Buttons