import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Page, FormGroup, LoadingPage, Input, RadioGroup, Button } from '@punchcard/core';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import coreAPI from 'api/coreAPI';
import EntityHeader from '@punchcard/core/components/EntityHeader';

const UsersPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const methods = useForm<AdminSchoolUserDTO>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			userTypeCode: 'Student',
			isActive: true,
		}
	});
	const { isDirty } = methods.formState;
	const userId: number = parseInt(params.userId ?? '0');
	const { control, reset, getValues } = methods;
	const pageTitle = userId === 0 ? t('adminUsers.add_admin_user') : t('adminUsers.edit_admin_user');

	React.useEffect(() => {
		async function fetchData() {
			setLoading(true);
			const school = await adminAPI.school.getSchoolUser(userId);
			reset(school);
			setLoading(false);
		}
		fetchData();
	}, [reset, userId]);

	const submitData = async () => {
		setLoading(true);
		const data = getValues();
		const dto: UserDetailsDTO = {
			id: userId,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			userType: data.userTypeCode,
			isActive: data.isActive,
			tenantId: 1,
			roleIds: [],
		};
		const user = await coreAPI.postTenantUser(dto);
		if (user) {
			toast.success(t('users.user_saved'));
			navigate(-1);
		} else {
			toast.error(t('users.unable_to_save_user'));
		}
		setLoading(false);
	};

	if (loading) {
		return <LoadingPage />;
	}
	return (
		<Page className="bg-light p-3 ps-5" title={pageTitle} >
			<FormProvider {...methods}>
				<div className="container-fluid">
					<div className="row">
						<div className=" col-lg-7">
							<EntityHeader title={pageTitle} parentRoute={'/admin/users'} isDirty={isDirty} />
							<div className=" bg-white p-3 rounded-2 mt-3">
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="firstName"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.first_name')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="lastName"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.last_name')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="email"
											rules={{
												required: t('this_field_is_required'),
											}}
											render={({ field, fieldState }) =>
												<FormGroup label={t('users.email_address')} fieldState={fieldState} className="pb-2" required={true}>
													<Input
														{...field}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="userTypeCode"
											render={({ field }) =>
												<FormGroup label={t('users.user_type')} className="pb-2" >
													<RadioGroup
														options={[
															{ label: 'Admin', value: 'Admin' },
															{ label: 'School Staff', value: 'Teacher' },
															{ label: 'Student', value: 'Student' }
														]}
														value={field.value}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Controller
											control={control}
											name="isActive"
											render={({ field }) =>
												<FormGroup label={t('users.status')} className="pb-2" >
													<RadioGroup
														options={[
															{ label: 'Active', value: true },
															{ label: 'InActive', value: false }
														]}
														value={field.value ?? true}
														onChange={field.onChange}
													/>
												</FormGroup>
											}
										/>
									</div>
								</div>
								<div>
									<Button onClick={submitData} className="btn-primary">{userId === 0 ? t('adminUsers.send_invite') : t('navigation.save')}</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</FormProvider>
		</Page >
	);
};

export default UsersPage;
