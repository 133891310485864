import axios from 'axios';

const userAPI = {
	postUserTerms: async (): Promise<boolean> => {
		const response = await axios.post('/postagreedtoterms');
		return response.data as boolean;
	},
	getUserTermHtml: async (): Promise<string> => {
		const response = await axios.get('/getagreedtotermshtml', {
			headers: {
				'Content-Type': 'text/html',
			},
		});
		return response.data as string;

	}
};

export default userAPI;