import { Page } from '@punchcard/core';
import React from 'react'
import Buttons from 'styling/Buttons';
import Colours from 'styling/Colours';
import Forms from 'styling/Forms';
import Typography from 'styling/Typography';

function StylingTest() {
    const [activeComponent, setActiveComponent] = React.useState('Forms')

    const handleClick = (component : string) => {
      setActiveComponent(component);
    };

    const renderComponent = () => {
        switch (activeComponent) {
          case 'Colours':
            return <Colours />;
          case 'Typography':
            return <Typography />;
          case 'Buttons':
            return <Buttons />;
          case 'Forms':
            return <Forms />;
          default:
            return null;
        }
      };

      return (
        <Page>
          <div>
            <button onClick={() => handleClick('Colours')}>Colours</button>
            <button onClick={() => handleClick('Typography')}>Typography</button>
            <button onClick={() => handleClick('Buttons')}>Buttons</button>
            <button onClick={() => handleClick('Forms')}>Forms</button>
            {renderComponent()}
          </div>
        </Page>
      );
}

export default StylingTest