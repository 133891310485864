/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormGroup, Checkbox, LoadingScreen, ModalOverlay, Select } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

interface IUnassignedData {
	label: string;
	value: number | string;
	id: number;
}

interface ISidebarModalForAddingDataProps {
	submitData: (selectedData: number[]) => Promise<void>;
	getUnassignedDataList: () => Promise<IUnassignedData[]>;
	// unassignedDataList: IUnassignedData[];
	selectErrorMessage?: string;
	addSelectAllOption?: boolean;
	selectAllLabel?: string;
	showAssignDataModal: boolean;
    setShowAssignDataModal: (showAssignDataModal: boolean) => void;
	headerLabel: string;
	confirmButtonLabel: string;
	cancelButtonLabel: string;
	formLabel: string;
}

interface IAssignData
{
    dataList: number[];
}

const SidebarModalForAddingData = (props: ISidebarModalForAddingDataProps) => {
	const {
		submitData,
		getUnassignedDataList,
		// unassignedDataList,
		selectErrorMessage,
		addSelectAllOption = false,
		selectAllLabel,
		showAssignDataModal,
		setShowAssignDataModal,
		headerLabel,
		confirmButtonLabel,
		cancelButtonLabel,
		formLabel,
	} = props;
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [selectedData, setSelectedData] = React.useState<number[]>([]);
	const [unassignedDataList, setUnassignedDataList] = React.useState<IUnassignedData[]>([]);
	const allSelected = selectedData.length === unassignedDataList.length;

	const methods = useForm<IAssignData>({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			dataList: [],
		},
	});
	const { control, setError } = methods;

	async function getUnassignedData() {
		setLoading(true);
		const unassignedData = await getUnassignedDataList();
		setUnassignedDataList(unassignedData || []);
		setLoading(false);
	}

	React.useEffect(() => {
		getUnassignedData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showAssignDataModal]);

	const onSubmitDataClick = async () => {
		setLoading(true);

		if (selectedData.length === 0) {
			setError('dataList', {
				type: 'manual',
				message: selectErrorMessage,
			});
			setLoading(false);
			return;
		}

		try {
			await submitData(selectedData);
			setShowAssignDataModal(false);
			setSelectedData([]);
		} catch (error) {
			throw new Error(error);
		} finally {
			setLoading(false);
		}
	};

	const CheckboxOptions = (props: any) => {
		return (
			<components.Option {...props}>
				<div className="d-flex flex-row">
					<Checkbox
						checked={props.isSelected}
						onChange={() => null}
					/>
					<label>{props.data.label}</label>
				</div>
			</components.Option>
		);
	};

	const MenuWithSelectAll = (props: any) => {
		return (
			<components.Menu {...props}>
				{addSelectAllOption && (
					<div
						className="d-flex align-items-center flex-row select-menu"
						onClick={handleSelectAllChange}
					>
						<Checkbox
							checked={allSelected}
							onChange={handleSelectAllChange}
						/>
						<label>{selectAllLabel ? selectAllLabel : t('select_all')}</label>
					</div>
				)}
				{props.children}
			</components.Menu>
		);
	};

	const handleSelectAllChange = () => {
		if (allSelected) {
			setSelectedData([]);
		} else {
			setSelectedData(unassignedDataList.map(data => data.id));
		}
	};

	const handleChange = (selectedOption: any) => {
		setSelectedData(selectedOption || []);
	};


	return (
		<ModalOverlay
			isOpen={showAssignDataModal}
			modalSize="lg"
			onRequestClose={() => setShowAssignDataModal(false)}
			headerChildren={headerLabel}
			confirmButtonChildren={confirmButtonLabel}
			cancelButtonChildren={cancelButtonLabel}
			confirmButtonAction={() => {
				onSubmitDataClick();
			}}
			cancelButtonAction={() => {
				setShowAssignDataModal(false);
				setSelectedData([]);
			}}
			modalPosition="right"
			hideCloseButton={true}
			shouldCloseOnOverlayClick={false}
		>
			<div>
				<FormProvider {...methods}>
					<Controller
						control={control}
						name="dataList"
						render={({ fieldState }) =>
							<FormGroup fieldState={fieldState} label={formLabel}>
								<Select
									options={unassignedDataList.map(data => ({ label: data.label, value: data.value }))}
									isMulti={true}
									value={selectedData}
									onChange={handleChange}
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									components={{ Option: CheckboxOptions, Menu: MenuWithSelectAll }}
								/>
							</FormGroup>
						}
					/>
				</FormProvider>
			</div>
		</ModalOverlay>
	);
};

export default SidebarModalForAddingData;
