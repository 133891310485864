import React from 'react';
import { ErrorPage } from '@punchcard/core';
import NavBar from 'components/navigation/NavBar';
import FourOhFour from '@punchcard/core/assets/404.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const FourOhFourPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<NavBar />
			<ErrorPage
				numberError="404"
				imageSrc={FourOhFour}
				message={t('error.message404')}
				description={t('error.description404')}
				buttonMessage={t('error.back_to_home_page')}
				buttonAction={()=>navigate('/')}
			/>
		</React.Fragment>
	);
};

export default FourOhFourPage;
