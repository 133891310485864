import React from 'react';
import { Button, Select } from '@punchcard/core';
import { IconChevronLeft, IconChevronRight } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    currentPage: number;
    itemsPerPage: number;
    totalItems?: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (size: number) => void;
}

const PaginationOptions = [
	{ value: 5, label: '5 rows' },
	{ value: 10, label: '10 rows' },
	{ value: 25, label: '25 rows' },
	{ value: 50, label: '50 rows' },
];

function Pagination(props: IProps) {
	const { t } = useTranslation();
	const { itemsPerPage, currentPage, totalItems, onPageChange, onPageSizeChange } = props;
	const totalPage = Math.ceil((totalItems ?? 0) / itemsPerPage);

	const defaultOption = PaginationOptions.find(option => option.value === itemsPerPage);

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			const newPageIndex = currentPage - 1;
			onPageChange(newPageIndex);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalPage - 1) {
			const nextPageIndex = currentPage + 1;
			onPageChange(nextPageIndex);
		}
	};

	return (
		<div className="p-2">
			<div className="d-flex align-items-center me-3">
				<Button
					className="btn-icon btn-ghost-primary p-0 me-2"
					onClick={handlePreviousPage}
					disabled={currentPage === 0}
					aria-label="Previous Page"
					role="button"
				>
					<IconChevronLeft className="icon-22"/>
				</Button>
				<label className="me-2" htmlFor="items-per-page-select">{t('pagination.page')} {currentPage + 1} {t('pagination.of')} {totalPage}</label>
				<Select
					options={PaginationOptions}
					defaultValue={defaultOption}
					menuPlacement="top"
					onChange={(selectedOption) => onPageSizeChange(selectedOption as number)}
					aria-label="Items Per Page"
				/>
				<Button
					className="btn-icon btn-ghost-primary p-0 ms-2"
					onClick={handleNextPage}
					disabled={currentPage === totalPage - 1}
					aria-label="Next Page"
					role="button"
				>
					<IconChevronRight className="icon-22"/>
				</Button>
			</div>
		</div>
	);
}

export default Pagination;