import React, { MouseEventHandler } from 'react';
import { Button } from './Button';
import { IconButton } from './IconButton';

interface IActionIconProps {
	title: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	disabled?: boolean;
	text?: string;
}

export const ClearFilterButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => (
	<Button className="btn btn-outline-primary py-1" onClick={onClick}>Clear</Button>
);

export const ActionIcon = ({ title, onClick, icon: Icon, disabled = false, text }: IActionIconProps) => {
	if (text) {
		return (
			<Button
				className="pl-0 btn-link btn-ghost-primary"
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				{Icon && <Icon />}
				{text && <div className="ms-1">{text}</div>}
			</Button>
		);
	} else {
		return (
			<IconButton
				className="pl-0 btn-link btn-ghost-primary"
				title={title}
				onClick={onClick}
				disabled={disabled}
			>
				{Icon && <Icon />}
				{text && <div className="ms-1">{text}</div>}
			</IconButton>
		);
	}
};
