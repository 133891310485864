import React from 'react';
import { Button, FormGroup, Input } from '@punchcard/core';
import { IconActivity, IconClose } from '@punchcard/core/icons';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CourseActivityComponentProps {
	activityIndex: number;
	removeActivity: (index: number) => void;
	name: string;
}

const CourseActivityComponent = (CourseActivityComponentProps: CourseActivityComponentProps) => {
	const { t } = useTranslation();
	const { control } = useFormContext();
	const { activityIndex, removeActivity, name } = CourseActivityComponentProps;

	return (
		<div className="bg-quaternary px-4 py-2 mb-3 rounded-3">
			<div className="d-flex justify-content-between align-items-center mb-3">
				<h6 className="mb-0 d-flex align-items-center"><IconActivity  className="me-1 icon-27"/>{activityIndex + 1}: {t('courses.activity')}</h6>
				<Button className="btn-ghost-primary me-n3 btn-sm" onClick={() => removeActivity(activityIndex)} icon={<IconClose className="icon-22"/>}>{t('courses.remove')}</Button>
			</div>
			<Controller
				control={control}
				name={`${name}.${activityIndex}.activityName`}
				defaultValue=""
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.activity_name')}>
						<Input
							{...field}
							placeholder={t('courses.activity_name')}
						/>
					</FormGroup>
				)}
			/>
			<Controller
				control={control}
				name={`${name}.${activityIndex}.pageNumbers`}
				defaultValue=""
				render={({ field }) => (
					<FormGroup className="pb-2" label={t('courses.pdf_page_range')}>
						<p>{t('courses.use_commas_to_split')}</p>
						<Input
							{...field}
							placeholder={t('courses.page_numbers')}
						/>
					</FormGroup>
				)}
			/>
		</div>
	);
};
export default CourseActivityComponent;


