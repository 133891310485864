function Colours() {
    return (
        <div>
            <h1>Colours</h1>
            <h2>Brand colours</h2>
            <div className="d-flex justify-content-between">
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-primary'>Primary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-primary-100'>100</div>
                        <div className='col-3 bg-primary-200'>200</div>
                        <div className='col-3 bg-primary-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-primary-400'>400</div>
                        <div className='col-3 bg-primary-500'>500</div>
                        <div className='col-3 bg-primary-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-primary-700'>700</div>
                        <div className='col-3 bg-primary-800'>800</div>
                        <div className='col-3 bg-primary-900'>900</div>
                    </div>
                </div>
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-secondary'>Secondary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-secondary-100'>100</div>
                        <div className='col-3 bg-secondary-200'>200</div>
                        <div className='col-3 bg-secondary-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-secondary-400'>400</div>
                        <div className='col-3 bg-secondary-500'>500</div>
                        <div className='col-3 bg-secondary-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-secondary-700'>700</div>
                        <div className='col-3 bg-secondary-800'>800</div>
                        <div className='col-3 bg-secondary-900'>900</div>
                    </div>
                </div>
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-tertiary'>Tertiary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-tertiary-100'>100</div>
                        <div className='col-3 bg-tertiary-200'>200</div>
                        <div className='col-3 bg-tertiary-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-tertiary-400'>400</div>
                        <div className='col-3 bg-tertiary-500'>500</div>
                        <div className='col-3 bg-tertiary-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-tertiary-700'>700</div>
                        <div className='col-3 bg-tertiary-800'>800</div>
                        <div className='col-3 bg-tertiary-900'>900</div>
                    </div>
                </div>
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-quaternary'>Primary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-quaternary-100'>100</div>
                        <div className='col-3 bg-quaternary-200'>200</div>
                        <div className='col-3 bg-quaternary-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-quaternary-400'>400</div>
                        <div className='col-3 bg-quaternary-500'>500</div>
                        <div className='col-3 bg-quaternary-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-quaternary-700'>700</div>
                        <div className='col-3 bg-quaternary-800'>800</div>
                        <div className='col-3 bg-quaternary-900'>900</div>
                    </div>
                </div>
            </div>
            <h2>Contextual colours</h2>      
            <div className="d-flex">
                <div className='col-3 bg-light'>Light</div>
                <div className='col-3 bg-dark text-white'>Dark</div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-danger'>Danger 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-danger-100'>100</div>
                        <div className='col-3 bg-danger-200'>200</div>
                        <div className='col-3 bg-danger-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-danger-400'>400</div>
                        <div className='col-3 bg-danger-500'>500</div>
                        <div className='col-3 bg-danger-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-danger-700'>700</div>
                        <div className='col-3 bg-danger-800'>800</div>
                        <div className='col-3 bg-danger-900'>900</div>
                    </div>
                </div>
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-info'>Primary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-info-100'>100</div>
                        <div className='col-3 bg-info-200'>200</div>
                        <div className='col-3 bg-info-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-info-400'>400</div>
                        <div className='col-3 bg-info-500'>500</div>
                        <div className='col-3 bg-info-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-info-700'>700</div>
                        <div className='col-3 bg-info-800'>800</div>
                        <div className='col-3 bg-info-900'>900</div>
                    </div>
                </div>
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-success'>Primary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-success-100'>100</div>
                        <div className='col-3 bg-success-200'>200</div>
                        <div className='col-3 bg-success-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-success-400'>400</div>
                        <div className='col-3 bg-success-500'>500</div>
                        <div className='col-3 bg-success-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-success-700'>700</div>
                        <div className='col-3 bg-success-800'>800</div>
                        <div className='col-3 bg-success-900'>900</div>
                    </div>
                </div>
                <div className="w-100">
                    <div className='row'>
                        <div className='col-9 bg-warning'>Primary 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-warning-100'>100</div>
                        <div className='col-3 bg-warning-200'>200</div>
                        <div className='col-3 bg-warning-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-warning-400'>400</div>
                        <div className='col-3 bg-warning-500'>500</div>
                        <div className='col-3 bg-warning-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-warning-700'>700</div>
                        <div className='col-3 bg-warning-800'>800</div>
                        <div className='col-3 bg-warning-900'>900</div>
                    </div>
                </div>
            </div>
            <h2>Grays</h2>
            <div className="d-flex">
                <div className="d-flex flex-column col-3">
                    <div className='col-9 bg-white'>White</div>
                    <div className='col-9 bg-black text-white'>Black</div>
                </div>
                <div className="col-3">
                    <div className='row'>
                        <div className='col-9 bg-gray'>Gray 500</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-gray-100'>100</div>
                        <div className='col-3 bg-gray-200'>200</div>
                        <div className='col-3 bg-gray-300'>300</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-gray-400'>400</div>
                        <div className='col-3 bg-gray-500'>500</div>
                        <div className='col-3 bg-gray-600'>600</div>
                    </div>
                    <div className='row'>
                        <div className='col-3 bg-gray-700'>700</div>
                        <div className='col-3 bg-gray-800'>800</div>
                        <div className='col-3 bg-gray-900'>900</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Colours