import React, { useState } from 'react';
import { Button, Page } from '@punchcard/core';
import { useMsal } from '@azure/msal-react';

import { IconLogout } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import userAPI from '../../api/userAPI';



const TermsAndConditions = () => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const [htmlContent, setHtmlContent] = useState<string>('');

	React.useEffect(() => {
		const fetchHtmlContent = async () => {
			try {
				const response = await userAPI.getUserTermHtml();
				setHtmlContent(response);
			} catch (error) {
				console.error('Error fetching the HTML content', error);
			}
		};
		fetchHtmlContent();
	}, []);
	const handleContinue = async () => {
		try {
			const terms: boolean = await userAPI.postUserTerms();
			if (terms) {
				window.location.href = '/';
			}
		} catch (error) {
			console.error('error saving terms status', error);
		}
	};
	return (
		<Page className="bg-gray-200" title="Terms and Conditions">
			<div className="d-flex justify-content-center align-items-center vh-100">
				<div className="modal-dialog modal-dialog-centered " style={{ height: '80vh', width: '80%' }}>
					<div className="modal-content p-4 bg-light rounded-2" style={{ height: '100%' }}>
						<div className="modal-header p-0 justify-self-left">
							<h5 className="modal-title">
								{t('termsAndConditions.terms_of_service')}
							</h5>
						</div>
						<div className="modal-body" style={{ maxHeight: '60vh', overflowY: 'auto' }}>

							<div dangerouslySetInnerHTML={{ __html: htmlContent }} />

						</div>
						<div className="modal-footer d-flex justify-content-between align-items-center pt-3">
							<p className="mb-0 text-end">{t('termsAndConditions.agree_to_terms')}</p>
							<div>
								<Button className="btn-ghost-primary me-3" onClick={() => instance.logoutRedirect()} aria-label="Logout">
									{t('logout')}
									<IconLogout className="ms-2" aria-hidden="true" />
								</Button>
								<Button onClick={handleContinue} className="btn btn-primary">
									{t('termsAndConditions.continue')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default TermsAndConditions;
