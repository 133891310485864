import React from 'react';
import { Button, DataTable, Page, PageHero, StatusFilterMethod, setStatusCellValue, filterByStatus, LoadingScreen, ModalOverlay, SidebarModalForAddingData } from '@punchcard/core';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconClose } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';

const CourseSchoolsListPage = () => {
	const location = useLocation();
	const params = useParams();
	const courseId: number = parseInt(params.courseId ?? '0');
	const { t } = useTranslation();
	const [courseSchools, setCourseSchools] = React.useState<AdminCourseSchoolDTO>();
	const [unassignedSchools, setUnassignedSchools] = React.useState<AdminSchoolDTO[]>();
	const [loading, setLoading] = React.useState<boolean>(true);
	const [showAssignSchoolsModal, setShowAssignSchoolsModal] = React.useState<boolean>(false);
	const [showRemoveSchoolModal, setShowRemoveSchoolModal] = React.useState<boolean>(false);
	const [selectedCourseSchool, setSelectedCourseSchool] = React.useState<AdminCourseSchoolCourseDTO | null>(null);

	async function getCourseSchoolsData() {
		setLoading(true);
		const courseSchoolsResponse = await adminAPI.schoolCourse.getSchoolsByCourse(courseId);
		if (courseSchoolsResponse !== null) {
			setCourseSchools(courseSchoolsResponse);
		} else {
			toast.error(t('unable_to_retrieve_school_list'));
		}
		setLoading(false);
	}

	React.useEffect(() => {
		getCourseSchoolsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseId]);

	const displayRemoveSchoolModal = (row: RowInfo) => {
		setSelectedCourseSchool(row.original);
		setShowRemoveSchoolModal(true);
	};

	const removeSchoolsToCourse = async () => {
		if (selectedCourseSchool) {
			const result = await adminAPI.schoolCourse.deleteSchoolCourse(selectedCourseSchool.schoolCourseId);
			if (result) {
				setShowRemoveSchoolModal(false);
				getCourseSchoolsData();
				toast.success(t('courses.successfully_removed_school'));
			} else {
				toast.error(t('courses.unable_to_remove_school'));
			}
		}
	};

	const addSchoolsToCourse = () => {
		setShowAssignSchoolsModal(true);
	};

	const onAssignSchoolsToCourse = async (selectedSchools: number[]) => {
		const courseSchools = await adminAPI.schoolCourse.postSchoolCourse(courseId, selectedSchools);
		if (courseSchools) {
			toast.success(t('courses.successfully_assigned_schools', { numberOfSchools: courseSchools.courseSchools.length }));
			getCourseSchoolsData();
		} else {
			toast.error(t('courses.unable_to_give_access_to_school'));
		}
	};

	const getUnassignedSchoolsList = async () => {
		const schools = await adminAPI.schoolCourse.getUnassignedSchoolsByCourse(courseId);
		if (schools !== null) {
			setUnassignedSchools(schools);
			return schools.map((school) => {
				return {
					id: school.id,
					value: school.id,
					label: school.schoolName
				};
			});
		} else {
			toast.error(t('unable_to_retrieve_school_list'));
		}
		return [];
	};

	// This is defined here so that the columns array isn't recreated every time the render function is called.
	const columns = [
		{
			Header: 'School Course Id',
			accessor: 'schoolCourseId',
			show: false
		},
		{
			Header: t('schools.school_name'),
			accessor: 'schoolName'
		},
		{
			Header: t('schools.school_status'),
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<Button
						className="me-3 btn-ghost-primary"
						title={t('courses.remove')}
						onClick={() => displayRemoveSchoolModal(rowInfo)}
						icon={<IconClose />}
					/>
				</React.Fragment>,
			Header: '',
			showClearButton: true,
			id: 'Actions'
		},

	];
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Page title={t('schools.school_list')} className="bg-light">
			<PageHero
				title={t('schools.school_list')}
				className="justify-content-between"
				parentRoute={`/courses${location.search.replace('?courses=', '')}`}
				elementsBeforeTitle={
					<h6>{courseSchools?.courseName}</h6>}
			>
				<Button onClick={addSchoolsToCourse} className="btn-primary" icon={<IconAdd />}>{t('schools.add_schools')}</Button>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={courseSchools?.courseSchools}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('courses.no_schools_selected_for_course', { courseName: courseSchools?.courseName })}
					loading={loading}
				/>
			</div>
			<SidebarModalForAddingData
				submitData={onAssignSchoolsToCourse}
				getUnassignedDataList={getUnassignedSchoolsList}
				selectErrorMessage={t('courses.select_at_least_one_school')}
				addSelectAllOption={true}
				selectAllLabel={t('courses.select_all_schools', { count: unassignedSchools?.length })}
				showAssignDataModal={showAssignSchoolsModal}
				setShowAssignDataModal={setShowAssignSchoolsModal}
				headerLabel={t('courses.select_schools')}
				confirmButtonLabel={t('courses.allow_access')}
				cancelButtonLabel={t('cancel')}
				formLabel={t('schools.school_list')}
			/>
			<ModalOverlay
				isOpen={showRemoveSchoolModal}
				modalSize="md"
				onRequestClose={() => setShowAssignSchoolsModal(false)}
				headerChildren={t('courses.revoke_access')}
				confirmButtonChildren={t('confirm')}
				cancelButtonChildren={t('cancel')}
				hideCloseButton={true}
				confirmButtonAction={() => {
					removeSchoolsToCourse();
				}}
				cancelButtonAction={() => {
					setShowRemoveSchoolModal(false);
				}}
			>
				{t('courses.revoke_access_message', { courseName: courseSchools?.courseName, schoolName: selectedCourseSchool?.schoolName })}
			</ModalOverlay>
		</Page>
	);

};

export default CourseSchoolsListPage;