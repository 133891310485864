import React from 'react';
import classNames from 'classnames';
interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	isSwitch?: boolean; // switch is a reserved word
}

const Checkbox = React.forwardRef<HTMLInputElement, IProps>((props, ref) => {
	const { label, className, isSwitch, ...otherProps } = props;
	return (
		<div className={classNames(isSwitch ? 'form-switch' : 'form-check', className)}>
			<label className="form-check-label" htmlFor={props.id} onClick={handleOnClick}>
				<input ref={ref} className="form-check-input" type="checkbox" {...otherProps} />
				{label}
			</label>
		</div>
	);

	function handleOnClick(_e: React.MouseEvent) {
		if (props.disabled != true && props.disabled !== undefined) {
			if (props.onChange !== undefined) {
				props.onChange({} as React.ChangeEvent<HTMLInputElement>);
			}
		}
	}

});

export default Checkbox;