import React from 'react';
import { Button, Page } from '@punchcard/core';

interface IProps {
	numberError: string;
	imageSrc: string;
	message: string;
	description: string;
	buttonMessage: string;
	buttonAction: () => void;
}

const ErrorPage = (props: IProps) => {
	const { message, description, buttonMessage, imageSrc, numberError, buttonAction } = props;

	return (
		<Page title={numberError} className="bg-light d-flex flex-column justify-content-center align-items-center">
			<img src={imageSrc} alt="404 Icon"></img>
			<h4 className="text-quaternary-800 mt-5">{message}</h4>
			<span className="text-quaternary-800 mt-1">{description}</span>
			<Button className="mt-3" onClick={buttonAction}>{buttonMessage}</Button>
		</Page>
	);
};

export default ErrorPage;
