import React from 'react';
import Lottie from 'lottie-react';
import LylaLoading from '@punchcard/core/assets/lyla-loading.json';

interface IProps {
	message?: string;
}

function LoadingScreen(props : IProps) {
	const { message = 'Loading...' } = props;
	return (
		<div className="bg-light d-flex flex-column justify-content-center align-items-center flex-fill">
			<Lottie animationData={LylaLoading} />
			<span>{message}</span>
		</div>
	);
}

export default LoadingScreen;