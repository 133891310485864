import React from 'react';
import PickListProvider from './context/PickListContext';
import AuthProvider from './context/AuthContext';
import { SettingsContext } from './context/SettingsContext';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import AppNavigator from './AppNavigator';
import { AuthenticationResult, EventMessage, EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { LoadingScreen } from '@punchcard/core';



const App = () => {
	const { settings } = React.useContext(SettingsContext);
	const [isInitializingMsal, setIsInitializingMsal] = React.useState(true);
	const [isInitializingPickList, setIsInitializingPickList] = React.useState(true);

	const msalInstance = new PublicClientApplication({
		auth: {
			clientId: settings.b2CClientId,
			authority: settings.b2CSignInPolicyURL,
			knownAuthorities: settings.b2CKnownAuthorities,
			redirectUri: window.location.origin,
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: false,
		},
	});

	React.useEffect(() => {
		msalInstance.initialize().then(() => setIsInitializingMsal(false));
	}, []);

	if (isInitializingMsal === false) {
		console.log('msalInstance initialized');
		// Account selection logic is app dependent. Adjust as needed for different use cases.
		const accounts = msalInstance.getAllAccounts();
		if (accounts.length > 0) {
			msalInstance.setActiveAccount(accounts[0]);
		}

		msalInstance.addEventCallback((event: EventMessage) => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
				const payload = event.payload as AuthenticationResult;
				const account = payload.account;
				msalInstance.setActiveAccount(account);
			}
		});

		const logInRequest = {
			extraQueryParameters: {
				entity: 'Admin',
			}
		};
		return (
			<MsalProvider instance={msalInstance}>
				<MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={logInRequest}>
					<AuthenticatedTemplate>
						<AuthProvider>
							<PickListProvider setIsInitializing={setIsInitializingPickList}>
								{isInitializingPickList ? <LoadingScreen /> : <AppNavigator />}
							</PickListProvider>
						</AuthProvider>
					</AuthenticatedTemplate>
				</MsalAuthenticationTemplate>
			</MsalProvider>
		);
	}
	return <LoadingScreen />;
};


export default App;
