import React from 'react';
import { Button, DataTable, Page, PageHero, ActionIcon, setStatusCellValue, filterByStatus, StatusFilterMethod, LoadingScreen } from '@punchcard/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import adminAPI from 'api/AdminAPI';
import { useTranslation } from 'react-i18next';
import { IconAdd, IconEdit } from '@punchcard/core/icons';
import { RowInfo } from 'react-table';

const SchoolUserListPage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const [schoolUserList, setSchoolUserList] = React.useState<AdminSchoolUserDTO[]>([]);
	const [schoolName, setSchoolName] = React.useState<string>('');
	const [loading, setLoading] = React.useState<boolean>(true);
	const params = useParams();
	const tenantId: number = parseInt(params.tenantId ?? '0');
	React.useEffect(() => {
		async function getSchoolList() {
			const schools = await adminAPI.school.getSchoolUserList(tenantId);
			if (schools !== null) {
				setSchoolName(schools.schoolName);
				setSchoolUserList(schools.schoolUsers);
			} else {
				toast.error(t('unable_to_retrieve_schoo_user_list'));
			}
			setLoading(false);
		}
		getSchoolList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openSchoolUser = (row: RowInfo) => {
		navigate(`/schools/${tenantId}/users/${row.original.id}${location.search}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};
	const newUser = async () => {
		navigate(`/schools/${tenantId}/users/0`);
	};
	// This is defined here so that the columns array isn't recreated every time the render function is called.
	const columns = [
		{
			Header: 'User ID',
			accessor: 'id',
			show: false
		},
		{
			Header: t('users.full_name'),
			accessor: 'fullName'
		},
		{
			Header: t('users.email_address'),
			accessor: 'email',
		},
		{
			Header: t('users.user_type'),
			accessor: 'userTypeCode',
		},
		{
			Header: t('users.user_status'),
			accessor: 'isActive',
			Cell: setStatusCellValue,
			filterMethod: filterByStatus('isActive'),
			Filter: StatusFilterMethod
		},
		{
			accessor: 'Actions',
			Cell: (rowInfo: RowInfo) =>
				<React.Fragment>
					<ActionIcon title="Edit" icon={IconEdit} onClick={() => { openSchoolUser(rowInfo); }} />
				</React.Fragment>,
			showClearButton: true,
			Header: '',
			id: 'Actions'
		},

	];
	if (loading) {
		return <LoadingScreen />;
	}
	return (
		<Page title={t('schools.school_user_list')} className="bg-light p-3 ps-4">
			<PageHero title={t('schools.school_user_list')} parentRoute="/schools" elementsBeforeTitle={<h6>{schoolName}</h6>}>
				<div className="d-flex justify-content-between">
					<div className="d-flex justify-content-end">
						<Button onClick={newUser} className="btn-primary"><IconAdd />{t('users.add_user')}</Button>
					</div>
				</div>
			</PageHero>
			<div className="container-fluid">
				<DataTable
					data={schoolUserList}
					columns={columns}
					resizable={true}
					sortable={true}
					filterable={true}
					noDataText={t('users.no_users_found')}
					loading={loading}
				/>
			</div>
		</Page>
	);

};

export default SchoolUserListPage;